import React, { useCallback } from 'react'
import { ReactComponent as Delete } from 'assets/delete.svg'
import { ReactComponent as Plus } from 'assets/plus.svg' /* 
import { ReactComponent as Updated } from 'assets/updated.svg' */
import { ReactComponent as Visibility } from 'assets/visibility2.svg'
import { toast } from 'react-toastify'
import { ReactComponent as Down } from 'assets/down-chevron.svg'
import { nanoid } from '@reduxjs/toolkit'
import useCalcs from './useCalcs'

const descuentoDefault = {
  isEdit: false,
  descripcion: '',
  descuentoVtaDemPK: {},
  monto: 0,
  valor: 0,
  porcentaje: 0,
  valorPorcentaje: 'M',
}

const Descuentos = ({ factura, handleChange, setFactura, setSection }) => {
  const { isEdit } = factura

  const { getTotalDescontado } = useCalcs(factura)

  const getDescuento = useCallback((p, d) => {
    const { monto, porcentaje } = d
    const { precioVenta, cantidad } = p

    if (monto > 0) {
      const descuento = precioVenta * cantidad - monto

      return descuento < 0 ? null : monto
    } else if (porcentaje > 0) {
      return precioVenta * cantidad * (porcentaje / 100)
    }
  }, [])

  const handleAddDescuento = useCallback(() => {
    const descuento = getDescuento(factura.productEnLinea, factura.descuentoEnLinea)

    console.log("handleAddDescuento ", factura.descuentoEnLinea, descuento)

    if (factura.descuentoEnLinea.descripcion == "") {
      toast.error('Ingrese la descripción del descuento')
      return
    }

    if (!descuento) {
      toast.error('El descuento es mayor al precio de los productos.')
      return
    }

    if (factura?.productEnLinea?.descuentoVtaDemList?.length > 0) {
      toast.error('Solo es posible agregar un descuento')
      return
    }

    setFactura((prev) => {
      //const descuento = getDescuento(prev.productEnLinea, prev.descuentoEnLinea)

      console.log("descto eb lie ", prev.descuentoEnLinea)
      Object.assign(prev.descuentoEnLinea, { valor: prev.descuentoEnLinea.monto })

      if (prev.descuentoEnLinea.isEdit) {
        const { nano } = prev.descuentoEnLinea

        return {
          ...prev,
          productEnLinea: {
            ...prev.productEnLinea,
            descuento: (prev?.productEnLinea?.descuento ?? 0) + Number(descuento),
            descuentoVtaDemList: prev?.productEnLinea.descuentoVtaDemList.map((i) =>
              i.nano === nano ? prev.descuentoEnLinea : i
            ),
            otroImpuestoVtaDetmList: prev?.productEnLinea?.otroImpuestoVtaDetmList?.map((i) => ({
              ...i,
              monto:
                (+i.porcentaje / 100) *
                (prev?.productEnLinea?.precioVenta * prev?.productEnLinea?.cantidad - (getTotalDescontado() + descuento)),
            })),
          },
          descuentoEnLinea: { ...descuentoDefault },
        }
      }

      return {
        ...prev,
        productEnLinea: {
          ...prev.productEnLinea,
          descuento: (prev?.productEnLinea?.descuento ?? 0) + Number(descuento),
          descuentoVtaDemList: [
            ...(prev?.productEnLinea.descuentoVtaDemList ?? []),
            { ...prev.descuentoEnLinea, nano: nanoid() },
          ],
          otroImpuestoVtaDetmList: prev?.productEnLinea?.otroImpuestoVtaDetmList?.map((i) => ({
            ...i,
            monto:
              (+i.porcentaje / 100) *
              (prev?.productEnLinea?.precioVenta * prev?.productEnLinea?.cantidad - (getTotalDescontado() + descuento)),
          })),
        },
        descuentoEnLinea: { ...descuentoDefault },
      }
    })
  }, [setFactura, getDescuento, getTotalDescontado])

  const handleChangeTipo = useCallback(
    (e) => {
      setFactura((prev) => ({
        ...prev,
        descuentoEnLinea: {
          ...prev.descuentoEnLinea,
          valorPorcentaje: e.target.value,
          monto: 0,
          porcentaje: 0,
        },
      }))
    },
    [setFactura]
  )

  const handleDeleteDescuento = useCallback(
    (obj) => {
      const { nano } = obj

      setFactura((prev) => {
        const descuento = getDescuento(prev.productEnLinea, prev.descuentoEnLinea)
        return {
          ...prev,
          productEnLinea: {
            ...prev.productEnLinea,
            descuento: (prev?.productEnLinea?.descuento ?? 0) - Number(descuento),
            descuentoVtaDemList: prev.productEnLinea?.descuentoVtaDemList.filter((i) => i.nano !== nano),
          },
        }
      })
      handleClean()
    },
    [setFactura, getDescuento]
  )

  const handleSelectDescuento = useCallback(
    (obj) => {
      setFactura((prev) => ({
        ...prev,
        descuentoEnLinea: { ...obj, isEdit: true },
      }))
    },
    [setFactura]
  )

  const handleClean = useCallback(() => {
    console.log("handleClean ", descuentoDefault)
    setFactura((prev) => ({
      ...prev,
      descuentoEnLinea: { ...descuentoDefault },
    }))
  }, [setFactura])

  const handleGoBack = useCallback(() => {
    setSection('facturaElectronica/DetallesFactura')
    setFactura((prev) => ({
      ...prev,
      descuentoEnLinea: {
        valorPorcentaje: 'M',
      },
    }))
  }, [setSection, setFactura])

  return (
    <div className="sectionForm">
      <div className="BackSection" onClick={handleGoBack}>
        <Down /> Atrás
      </div>

      <div className="titleSectionForm" style={{ width: '100%' }}>
        <h3>Descuentos</h3>
        <button className="clearFormLineaDetalle" onClick={handleClean}>
          Limpiar Formulario
        </button>
      </div>
      {factura?.descuentoEnLinea && (
        <>
          <label htmlFor="nombreDescripcion" className="text" style={{ width: '100%' }}>
            Naturaleza
            <input
              name="descuentoEnLinea.descripcion"
              onChange={handleChange}
              value={factura?.descuentoEnLinea?.descripcion ?? ''}
              type="text"
              disabled={isEdit}
              placeholder="Naturaleza"
              id="nombreDescripcion"
            />
          </label>

          <label htmlFor="unidadMedida" className="text" style={{ width: '40%' }}>
            Tipo de Descuento
            <select
              name="descuentoEnLinea.valorPorcentaje"
              value={factura?.descuentoEnLinea?.valorPorcentaje ?? 'M'}
              id="unidadMedida"
              disabled={isEdit}
              onChange={handleChangeTipo}
            >
              <option value="M">Monto</option>
              <option value="P">Porcentaje</option>
            </select>
          </label>
        </>
      )}

      {factura?.descuentoEnLinea?.valorPorcentaje === 'M' && (
        <label htmlFor="monto" className="text" style={{ width: '40%' }}>
          Monto
          <input
            name="descuentoEnLinea.monto"
            type="number"
            min={0}
            disabled={isEdit}
            value={factura?.descuentoEnLinea?.monto ?? ''}
            placeholder="Monto"
            onChange={handleChange}
            id="monto"
          />
        </label>
      )}
      {factura?.descuentoEnLinea?.valorPorcentaje === 'P' && (
        <label htmlFor="porcentaje" className="text" style={{ width: '40%' }}>
          Porcentaje
          <input
            type="number"
            disabled={isEdit}
            name="descuentoEnLinea.porcentaje"
            value={factura?.descuentoEnLinea?.porcentaje ?? ''}
            placeholder="Procentaje"
            onChange={handleChange}
            id="porcentaje"
          />
        </label>
      )}
      {!isEdit && (
        <button className="btnForm" style={{ width: '20%' }} onClick={handleAddDescuento}>
          {isEdit ? <i className="bi bi-eye"></i> : factura.descuentoEnLinea?.isEdit ? <i className="bi bi-arrow-repeat"></i> : <i className="bi bi-plus"></i>}
          {factura.descuentoEnLinea?.isEdit ? 'Actualizar' : 'Agregar'}
        </button>
      )}

      <div className="listSection">
        <div className="headList">
          <ul>
            <li className="col-5">
              <b>Naturaliza</b>
            </li>
            <li className="col-2">
              <b>Porcentaje</b>
            </li>
            <li className="col-2">
              <b>Monto</b>
            </li>
            <li className="col-1">
              <b></b>
            </li>
          </ul>
        </div>
        <div className="bodyList">
          <ul>
            {factura?.productEnLinea?.descuentoVtaDemList?.length ? (
              factura?.productEnLinea?.descuentoVtaDemList.slice(0, 50).map((descuento, index) => (
                <li className="itemBodyList" key={descuento.nano ?? descuento.descripcion}>
                  <ul className="contentItemBodyList" inhover="out">
                    <li className="col-5 noAction">
                      <span>{descuento.descripcion}</span>
                    </li>
                    <li className="col-2 noAction">
                      <span>{descuento?.porcentaje < 1 || !descuento?.porcentaje ? 0 : descuento?.porcentaje + '%'}</span>
                    </li>
                    <li className="col-2 noAction">
                      <b>{!descuento?.monto ? 0 : `${factura?.idMoneda?.signo ?? ''} ${descuento.monto.toLocaleString('es')}`}</b>
                    </li>
                    <li className="col-1">
                      {isEdit ? null : (
                        <>
                          <button className="btnEditItem" onClick={() => handleSelectDescuento(descuento)}>
                            <Visibility />
                          </button>
                          <button className="btnDelete" onClick={() => handleDeleteDescuento(descuento)}>
                            <Delete />
                          </button>
                        </>
                      )}
                    </li>
                  </ul>
                </li>
              ))
            ) : (
              <li className="emptyResultTable">
                <span>No hay Descuentos</span>
              </li>
            )}
          </ul>
        </div>
      </div>
    </div>
  )
}

export default Descuentos
